/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <PTabView :activeIndex.sync="activeTab" class="mt-5">
          <PTabPanel header="Contrat d'objectifs">
            <ContratObjectifList />
          </PTabPanel>
          <!-- <PTabPanel header="Objectifs agents">
            <ObjectifAgentList />
          </PTabPanel> -->
          <!-- <PTabPanel header="Objectifs Antennes">
            <ObjectifAntenneList />
          </PTabPanel> -->
        </PTabView>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "@/mixins/auth-mixin";

import ObjectifAgentList from "./ObjectifAgentList.vue";
import ObjectifAntenneList from "./ObjectifAntenneList.vue";
import ContratObjectifList from "./ContratObjectifList.vue";

export default {
  props: {},
  components: {
    ObjectifAgentList,
    ObjectifAntenneList,
    ContratObjectifList,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      activeTab: 0,
    };
  },
  created() {},
  watch: {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
